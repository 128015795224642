export const TOKEN_KEY = "apitoken";

export const isAuthenticated = () => {
  console.log('isAuthenticated called');
  const token = localStorage.getItem(TOKEN_KEY);
  console.log('token:', token);
  return token !== null;
};

export const getToken = () => {
  console.log('getToken called');
  const token = localStorage.getItem(TOKEN_KEY);
  console.log('token:', token);
  return token;
};

export const login = (token) => {
  console.log('login called with token:', token);
  localStorage.setItem(TOKEN_KEY, token);
  console.log('token stored in localStorage');
};

export const logout = () => {
  console.log('logout called');
  localStorage.removeItem(TOKEN_KEY);
  console.log('token removed from localStorage');
};
