import "bootstrap/dist/css/bootstrap.css";
import "./assets/GlobalStyle.css";

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { SpinnerComponent } from "./components/Spinner";
import AuthProvider from "./context/AuthContext";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      {/* <SpinnerComponent /> */}
      <Suspense fallback={<SpinnerComponent />}>
        <LazyApp />
      </Suspense>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
